import React, { useState, useEffect } from "react";

// GraphQL
import { Link } from "react-router-dom";
import { Query } from "react-apollo";
import moment from "moment";
import {
  Badge,
  Card,
  message,
  Row,
  Col,
  Divider,
  Tag,
  Button,
  Statistic,
} from "antd";
import { orderBy } from "lodash";
import FontAwesome from "react-fontawesome";
import DualLineAxis from "components/stateless/dualLinesChart";
import { textFilter } from "react-bootstrap-table2-filter";
import Table from "components/stateless/antdTable";
import { Sorter } from "../../helpers/sorter";

/* App */
import {
  Loading,
  ScrollTable,
  showNotification,
} from "src/components/stateless"; // custom components
import { defaultSort } from "src/helpers";

import InvoiceForm from "./invoice";

import GET_EARNINGS_LIST from "src/graphql/queries/getAllEarnings.gql";
import { updateEarning, getAllEarnings } from "src/actions/earnings";
import { getChildrensOfId } from "src/actions/users";

function InvoicesList(props) {
  const [visible, setVisible] = useState(false);
  const [invoice, setInvoice] = useState({});
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [userChildrens, setUserChildrens] = useState([]);

  const showDrawer = async (id, amount, ref) => {
    setVisible(true);
    setInvoice({ id, amount, ref });
  };

  const onClose = () => {
    setVisible(false);
    setInvoice({});
  };

  const onSubmitPayment = async (values) => {
    console.log("envoi paiement", values);
    setVisible(false);
    try {
      setLoading(true);
      await updateEarning(values, props.client);
      setData({});
      message.success("Paiement enregistré avec succès!");
    } catch (err) {
      console.log(err);
      message.error("Erreur d'enregistrement!");
      setLoading(false);
    }
  };

  const renderRow = (curr) => {
    return {
      ...curr,
      key: curr.id,
      tva: curr.user.needTva ? curr.vatAmount + " €" : 0,
      nettyId: curr.transaction.nettyId,
      user: `${curr.from.firstname} ${curr.from.lastname}`,
      userId: curr.user.id,
      from: `${curr.from.firstname} ${curr.from.lastname}`,
      to: `${curr.user.firstname} ${curr.user.lastname}`,
      date: moment(curr.createdAt).format("DD/MM/YYYY HH:mm:ss"),
      // view: (
      //   <Link
      //     className="btn btn-primary"
      //     onClick={e => props.onEditClicked(curr.id)}
      //   >
      //     <i className="fa fa-eye" />
      //   </Link>
      // ),
      amount: curr.amount + " €",
      profit: curr.user.needTva
        ? `${parseFloat(curr.amount - curr.vatAmount).toFixed(2)} €`
        : `${curr.amount} €`,
      commissionAmount:
        parseFloat(curr.transaction.commissionAmount).toFixed(2) + " €",
      mandat: (
        <div>
          <span>
            <FontAwesome
              name="sign-in-alt"
              style={{
                color: "#1890ff",
              }}
            />
            Entrant:
            {curr.representativeInput
              ? ` ${curr.representativeInput.firstname} ${curr.representativeInput.lastname}`
              : " Aucun"}
          </span>
          <br />
          <span>
            <FontAwesome
              name="sign-out-alt"
              style={{
                color: "rgb(88, 196, 26)",
              }}
            />
            Sortant:
            {curr.user
              ? ` ${curr.user.firstname} ${curr.user.lastname}`
              : " Aucun"}
          </span>
        </div>
      ),
      state: curr.paymentDate ? (
        <Tag color="green">
          Réglement de {curr.payedAmount} € effectué le{" "}
          {moment(curr.paymentDate).format("DD/MM/YYYY")}
        </Tag>
      ) : (
        <div>
          <Tag color="red">En attente de paiement</Tag>
          {user.role.name === "Admin" && (
            <Button
              icon={
                <FontAwesome
                  name="money-bill-alt"
                  style={{
                    color: "rgb(88, 196, 26)",
                    marginRight: 5,
                  }}
                />
              }
              size="small"
              onClick={(e) =>
                showDrawer(
                  curr.id,
                  curr.user.needTva
                    ? parseFloat(curr.amount - curr.vatAmount).toFixed(2)
                    : curr.amount,
                  curr.invoiceId
                )
              }
            >
              Payer
            </Button>
          )}
        </div>
      ),
    };
  };

  useEffect(() => {
    const loadGains = async () => {
      const data = await getAllEarnings(props.client);
      const userChildrens = await getChildrensOfId(props.user.id, props.client);
      setUserChildrens(userChildrens);
      setData(data);
      setLoading(false);
    };
    loadGains();
  }, [data]);

  if (loading) {
    return <Loading />;
  }

  const { user } = props;

  const subColumns = [
    {
      dataField: "invoiceId",
      text: "Réf.",
      sort: true,
      sortValue: (cell, row) => parseInt(cell.substring(8)),
      sortCaret: (order, column) => {
        if (!order) return <span>  Desc/Asc</span>;
        else if (order === "asc")
          return (
            <span
              style={{
                float: "right",
              }}
            >
              <FontAwesome name="caret-up"></FontAwesome>
            </span>
          );
        else if (order === "desc")
          return (
            <span
              style={{
                float: "right",
              }}
            >
              <FontAwesome name="caret-down"></FontAwesome>
            </span>
          );
        return null;
      },
    },
    {
      dataField: "nettyId",
      text: "Id du bien netty",
    },
    {
      dataField: "transaction.commissionAmountHt",
      text: "Commission HT",
      formatter: (e) => `${parseFloat(e).toFixed(2)} €`,
    },
    {
      dataField: "date",
      text: "Date",
    },
    {
      dataField: "from",
      text: "De",
      searchable: false,
    },
    {
      dataField: "to",
      text: "Bénéficiaire",
    },
    {
      dataField: "type",
      text: "Type",
    },
    // {
    //   dataField: "amount",
    //   text: "Base",
    // },
    {
      dataField: "profit",
      text: "Honoraires HT",
    },
    {
      dataField: "state",
      text: "Status",
    },
  ];

  const defaultSorted = [
    {
      dataField: "invoiceId", // if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ];

  let gainsList = [];
  let invoicesStats = { payed: 0, pending: 0, totalPayed: 0, totalPending: 0 };

  if (user.role.name === "Admin") {
    gainsList = data.filter(
      (g) => g.type === "Répartition" && g.level !== -1 && g.level !== 0
    );
  } else {
    // Allowed user IDs include the current user and his childrens
    const userIdsAllowed = [user.id, ...userChildrens];
    // Find transaction IDs where the transaction belongs directly to the user or one of his children
    const transactionIdsUser = data
      .filter(
        (g) =>
          g.type === "Répartition" &&
          g.level !== -1 &&
          g.level !== 0 &&
          userIdsAllowed.includes(g.user.id)
      )
      .map((g) => g.transaction.id);
    // Now include all earnings with these transaction IDs (with valid levels) regardless of the invoice's owner
    gainsList = data.filter(
      (g) =>
        g.type === "Répartition" &&
        g.level !== -1 &&
        g.level !== 0 &&
        (userIdsAllowed.includes(g.user.id) ||
          transactionIdsUser.includes(g.transaction.id))
    );
  }

  if (user.role.name === "Admin") {
    // if not admin filter user own transactions or from his childrens
    gainsList.map((t) => {
      if (t.paymentDate) {
        invoicesStats.payed += 1;
        invoicesStats.totalPayed += t.payedAmount;
      } else {
        invoicesStats.pending += 1;
        invoicesStats.totalPending += t.amount;
      }
    });
  }

  console.log(invoicesStats);

  const list = gainsList.map((t) => renderRow(t));

  return (
    <div>
      <Row>
        <Col span={24}>
          <div>
            <Row
              gutter={16}
              style={{
                marginTop: 20,
              }}
            >
              <Col span={24}>
                {user.role.name === "Admin" && (
                  <Card
                    style={{ marginBottom: 20 }}
                    title={
                      <h5>
                        <FontAwesome
                          name="chart-line"
                          style={{
                            color: "#1890ff",
                          }}
                        />
                        Statistiques
                      </h5>
                    }
                  >
                    <div class="flex-container">
                      <div class="flex-item flex-item-1">
                        <Statistic
                          title="Factures en attente"
                          value={invoicesStats.pending}
                          prefix={
                            <FontAwesome
                              name="clock"
                              style={{ color: "#EE0505" }}
                            />
                          }
                        />
                      </div>
                      <div class="flex-item flex-item-2">
                        <Statistic
                          title="Total factures en attente"
                          value={`${parseFloat(
                            invoicesStats.totalPending / (1 + 20 / 100)
                          ).toFixed(2)} €`}
                        />
                      </div>
                      <div class="flex-item flex-item-3">
                        <Statistic
                          title="Factures réglées"
                          value={invoicesStats.payed}
                          prefix={
                            <FontAwesome
                              name="clipboard-check"
                              style={{ color: "#52C41A" }}
                            />
                          }
                        />
                      </div>
                      <div class="flex-item flex-item-4">
                        <Statistic
                          title="Total facturé"
                          value={`${parseFloat(
                            invoicesStats.totalPayed / (1 + 20 / 100)
                          ).toFixed(2)} €`}
                        />
                      </div>
                    </div>
                  </Card>
                )}
                <Card
                  title={
                    <h5>
                      <FontAwesome
                        name="list"
                        style={{
                          color: "#1890ff",
                        }}
                      />
                      Liste des factures
                    </h5>
                  }
                >
                  <ScrollTable
                    search
                    list={list}
                    sizePerPage={10}
                    columns={subColumns}
                    {...props}
                    defaultSorted={defaultSorted}
                  />
                </Card>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      {visible ? (
        <InvoiceForm
          visible={visible}
          onClose={onClose}
          invoice={invoice}
          onSubmitPayment={onSubmitPayment}
        />
      ) : null}
    </div>
  );
}

export default InvoicesList;
