import React from "react";
import { Query } from "react-apollo";
import moment from "moment";
import { Button, Popconfirm, Input, Pagination, Spin } from "antd";
import { orderBy } from "lodash";
import FontAwesome from "react-fontawesome";
import Pie from "components/stateless/Pie";
import { QuestionCircleOutlined } from "@ant-design/icons";
import {
  Loading,
  ScrollTable,
  showNotification,
} from "src/components/stateless";
import GET_TRANSACTIONS_LIST from "src/graphql/queries/getAllTransactions.gql";
import debounce from "lodash/debounce";

class TransactionsList extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      LinetoExpand: null,
      page: 1,
      pageSize: 5,
      search: "",
      searchInput: "",
    };

    this.debouncedSearch = debounce(this.triggerSearch, 500);
  }

  triggerSearch = (value) => {
    const regex = /^[a-z]{2}/i;
    if (regex.test(value)) {
      console.log("search", value);
      this.setState({ search: value, page: 1 });
    } else if (value === "") {
      this.setState({ search: "", page: 1 });
    }
  };

  handleSearchChange = (e) => {
    const value = e.target.value;
    this.setState({ searchInput: value });
    this.debouncedSearch(value);
  };

  render() {
    const { page, pageSize, search, searchInput } = this.state;

    return (
      <Query
        query={GET_TRANSACTIONS_LIST}
        variables={{ page, pageSize: String(pageSize), search }}
        fetchPolicy="network-only"
      >
        {({ loading, error, data }) => {
          if (error) {
            showNotification("error", "", error.message);
            return <div>Erreur lors du chargement des données</div>;
          }

          const Transactions = data && data.Transactions;
          const user = this.props.user;
          const listData =
            Transactions && Transactions.list ? Transactions.list.data : [];

          let transactionsList = listData;

          if (user.role.name !== "Admin") {
            transactionsList = transactionsList.filter(
              (t) =>
                t.user.id === user.id ||
                t.beneficiaries.find((u) => u.user.id === user.id)
            );
          }

          const list = transactionsList.map((curr) => ({
            ...curr,
            user: `${curr.user.firstname} ${curr.user.lastname}`,
            userId: curr.user.id,
            date: moment(curr.createdAt).format("DD/MM/YYYY HH:mm:ss"),
            view: (
              <Button
                type="primary"
                onClick={() => this.props.onEditClicked(curr.id)}
              >
                <i className="fa fa-eye" />
              </Button>
            ),
            commissionAmount: curr.commissionAmount + " €",
            prescriptors: curr.hasPrescriptor ? (
              <div>
                <span>
                  <FontAwesome
                    name="sign-in-alt"
                    style={{ color: "#1890ff" }}
                  />{" "}
                  Entrant:
                  {curr.inputPrescriptor
                    ? ` ${curr.inputPrescriptor.firstname} ${curr.inputPrescriptor.lastname}`
                    : " Aucun"}
                </span>
                <br />
                <span>
                  <FontAwesome
                    name="sign-out-alt"
                    style={{ color: "#58c41a" }}
                  />{" "}
                  Sortant:
                  {curr.outputPrescriptor
                    ? ` ${curr.outputPrescriptor.firstname} ${curr.outputPrescriptor.lastname}`
                    : " Aucun"}
                </span>
              </div>
            ) : (
              "Aucun"
            ),
            recommandations: curr.hasRecommandation ? (
              <div>
                <span>
                  <FontAwesome
                    name="sign-in-alt"
                    style={{ color: "#1890ff" }}
                  />{" "}
                  Entrante:
                  {curr.inputRecommandator
                    ? ` ${curr.inputRecommandator.firstname} ${curr.inputRecommandator.lastname}`
                    : " Aucune"}
                </span>
                <br />
                <span>
                  <FontAwesome
                    name="sign-out-alt"
                    style={{ color: "#58c41a" }}
                  />{" "}
                  Sortante:
                  {curr.outputRecommandator
                    ? ` ${curr.outputRecommandator.firstname} ${curr.outputRecommandator.lastname}`
                    : " Aucune"}
                </span>
              </div>
            ) : (
              "Aucune"
            ),
            mandat: (
              <div>
                <span>
                  <FontAwesome
                    name="sign-in-alt"
                    style={{ color: "#1890ff" }}
                  />{" "}
                  Entrant:
                  {curr.representativeInput
                    ? ` ${curr.representativeInput.firstname} ${curr.representativeInput.lastname}`
                    : " Aucun"}
                </span>
                <br />
                <span>
                  <FontAwesome
                    name="sign-out-alt"
                    style={{ color: "#9fc41a" }}
                  />{" "}
                  Sortant:
                  {curr.user
                    ? ` ${curr.user.firstname} ${curr.user.lastname}`
                    : " Aucun"}
                </span>
              </div>
            ),
            delete: (
              <Popconfirm
                icon={<QuestionCircleOutlined style={{ color: "red" }} />}
                onConfirm={() => this.props.onDeleteClicked(curr.id)}
                title="Voulez-vous vraiment supprimer cette transaction ?"
                okText="Oui"
                cancelText="Annuler"
              >
                <Button type="primary" danger>
                  <i className="fa fa-trash" />
                </Button>
              </Popconfirm>
            ),
          }));

          const expandRow = {
            renderer: (row) => {
              let oAmount = 0;
              let rAmount = 0;
              const data = orderBy(
                row.beneficiaries,
                (r) => r.amount,
                "desc"
              ).reduce(
                (c, n) => {
                  const key = n.level > 0 ? "repartition" : "others";
                  if (!c[key][n.user.id]) {
                    c[key][n.user.id] = {
                      value: 0,
                      type: `${n.user.firstname} ${n.user.lastname}`,
                    };
                  }
                  const value = n.user.needTva
                    ? n.amount - n.vatAmount
                    : n.amount;
                  c[key][n.user.id].value += value;
                  if (key === "repartition") rAmount += value;
                  else oAmount += value;
                  return c;
                },
                { repartition: {}, others: {} }
              );

              const olist = Object.values(data.others);
              const rlist = Object.values(data.repartition);

              return (
                <>
                  <div style={{ width: "50%", float: "left", minHeight: 400 }}>
                    <Pie
                      data={olist}
                      title="Honoraires"
                      content={`${oAmount} €`}
                    />
                  </div>
                  <div
                    style={{
                      width: "50%",
                      float: "right",
                      textAlign: "center",
                      minHeight: 400,
                      display: rlist.length ? "block" : "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {rlist.length ? (
                      <Pie
                        data={rlist}
                        title="Répartitions"
                        content={`${rAmount} €`}
                      />
                    ) : (
                      <h5>Aucune répartition sur cette transaction</h5>
                    )}
                  </div>
                </>
              );
            },
            onlyOneExpanding: true,
            expandByColumnOnly: true,
            nonExpandable: [0, 1, 2, 3, 4],
            showExpandColumn: true,
            expandColumnPosition: "right",
            expandHeaderColumnRenderer: () => <b>Bénéficiaires</b>,
            expandColumnRenderer: ({ expanded }) => (
              <FontAwesome
                name="chart-pie"
                style={{
                  color: expanded ? "#F1AF1D" : "#0BAC4C",
                  fontSize: 32,
                  display: "flex",
                  justifyContent: "center",
                }}
              />
            ),
          };

          const columns = [
            {
              dataField: "view",
              text: "",
              csvExport: false,
              headerStyle: () => ({ width: 28 }),
            },
            {
              dataField: "delete",
              text: "",
              csvExport: false,
              headerStyle: () => ({ width: 28 }),
            },
            { dataField: "nettyId", text: "Id du bien netty" },
            { dataField: "user", text: "Crée par" },
            { dataField: "date", text: "Date" },
            {
              dataField: "commissionAmountHt",
              text: "Commission (HT)",
              formatter: (e) => `${parseFloat(e).toFixed(2)} €`,
            },
            { dataField: "mandat", text: "Mandat" },
            { dataField: "prescriptors", text: "Prescripteurs" },
            { dataField: "recommandations", text: "Recommandations" },
          ];

          return (
            <div className="card">
              <div
                style={{
                  marginBottom: 16,
                  display: "flex",
                  gap: 12,
                  padding: 10,
                }}
              >
                <Input
                  placeholder="Rechercher..."
                  allowClear
                  onChange={this.handleSearchChange}
                  value={searchInput}
                  style={{ maxWidth: 300 }}
                />
              </div>
              <Spin spinning={loading}>
                <ScrollTable
                  search={false}
                  pagination={false}
                  list={list}
                  expandRow={expandRow}
                  columns={columns}
                  sizePerPage={pageSize}
                  {...this.props}
                  style={{ padding: 10 }}
                />
              </Spin>
              <Pagination
                current={page}
                pageSize={pageSize}
                defaultPageSize={5}
                pageSizeOptions={["5", "10", "20", "50"]}
                total={
                  Transactions && Transactions.list
                    ? Transactions.list.totalCount
                    : 0
                }
                showSizeChanger
                showQuickJumper
                onChange={(p, size) =>
                  this.setState({ page: p, pageSize: size })
                }
                style={{ marginTop: 16, textAlign: "right", padding: 10 }}
              />
            </div>
          );
        }}
      </Query>
    );
  }
}

export default TransactionsList;
