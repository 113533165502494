import React from "react";
import { Button } from "antd";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import ContainerDimensions from "react-container-dimensions";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit";
import FontAwesome from "react-fontawesome";
import { history } from "src/router/history";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";

const { SearchBar, ClearSearchButton } = Search;

const { ExportCSVButton } = CSVExport;

class ScrollTable extends React.PureComponent {
  constructor(props) {
    super(props);
    console.log(props);
    this.table = React.createRef();
    const pageCheck =
      this.props.history.location.search.match(/page=(\d{1,2})/);
    const currentPage = pageCheck ? parseInt(pageCheck[1], 10) : 1;
    this.state = {
      pathname: this.props.history.location.pathname,
      currentPath: this.props.history.location.search,
      page: this.props.page || currentPage,
      data: this.props.list || [],
      totalSize: (this.props.list && this.props.list.length) || 0,
      sizePerPage: this.props.sizePerPage || 5,
    };
  }

  componentDidMount = () => {
    const { pathname, search } = this.props.history.location;
    //console.log(location);
    const pageCheck = search.match(/page=(\d{1,2})/);
    const currentPage = pageCheck ? parseInt(pageCheck[1], 10) : 1;
    this.setState({
      pathname,
      currentPath: search,
      currentPage,
    });
  };

  componentWillReceiveProps = (newProps) => {
    const { pathname, search } = newProps.history.location;

    // pagination URL
    if (search !== this.state.currentPath) {
      const pageCheck = search.match(/page=(\d{1,2})/);
      const currentPage = pageCheck ? parseInt(pageCheck[1], 10) : 1;
      this.setState({
        pathname,
        currentPath: search,
        page: currentPage,
      });
    }

    // 🔧 ici on synchronise la taille de page
    if (newProps.sizePerPage !== this.state.sizePerPage) {
      this.setState({
        sizePerPage: newProps.sizePerPage,
      });
    }

    // synchro data
    if (this.state.data.length !== newProps.list) {
      this.setState({
        data: newProps.list,
      });
    }
  };

  onPageChange = (newPage) => {
    console.log(this.state);
    // const newPath = `${this.state.pathname}${
    //   this.state.currentPath.includes("&page")
    //     ? this.state.currentPath.split("&page")[0]
    //     : this.state.currentPath
    // }&page=${newPage}`;
    // history.push(newPath);
    this.setState({
      page: newPage,
    });
  };

  onTableChange = (type, { page, sizePerPage, searchText }) => {
    const { list, columns } = this.props;
    // setTimeout(() => {
    let newPage = page;
    let result = list;
    if (searchText) {
      result = list.filter((row) => {
        for (let cidx = 0; cidx < columns.length; cidx += 1) {
          const column = columns[cidx];
          let targetValue = row[column.dataField];
          if (targetValue !== null && typeof targetValue !== "undefined") {
            targetValue = targetValue.toString().toLowerCase();
            if (targetValue.indexOf(searchText) > -1) {
              return true;
            }
          }
        }
        return false;
      });
      // back to first page
      newPage = 1;
    }
    // calculation pagination
    const currentIndex = (newPage - 1) * sizePerPage;
    const data = searchText
      ? result.slice(currentIndex, currentIndex + sizePerPage)
      : list;
    const totalSize = result.length;
    const newPath = `${this.state.pathname}${
      this.state.currentPath.includes("&page")
        ? this.state.currentPath.split("&page")[0]
        : this.state.currentPath
    }&page=${newPage}`;
    if (newPage !== this.state.page) {
      history.push(newPath);
    }
    this.setState(() => ({
      currentPath: newPath,
      page: newPage,
      data,
      totalSize,
      sizePerPage,
    }));
    // }, 100);
  };

  customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );

  onSizePerPageChange = (page) => {
    console.log(page);
    this.setState({ sizePerPage: page });
  };

  render() {
    const { list, columns } = this.props;
    const { sizePerPage, totalSize, page, data } = this.state;

    const options = {
      custom: true,
      sizePerPage,
      totalSize,
      page,
      data: list,
      showTotal: true,
      paginationTotalRenderer: this.customTotal,
      sizePerPageList: [
        {
          text: "5",
          value: 5,
        },
        {
          text: "10",
          value: 10,
        },
        {
          text: "25",
          value: 25,
        },
        {
          text: "Tous",
          value: list.length,
        },
      ],
    };

    const MyExportCSV = (props) => {
      const handleClick = () => {
        props.onExport();
      };
      return (
        <div>
          <button
            className="ant-btn ant-btn-warning"
            onClick={handleClick}
            style={{
              fontSize: 12,
              width: 150,
              float: "left",
              margin: "15px 0 0 0",
              border: "none",
              paddingLeft: 0,
            }}
          >
            <FontAwesome
              name="file-excel"
              style={{
                color: "green",
              }}
            />{" "}
            Exporter CSV
          </button>
        </div>
      );
    };

    return (
      <React.Fragment>
        <ContainerDimensions>
          {({ width, height }) => (
            <ToolkitProvider
              keyField="id"
              data={data}
              height={height}
              columns={columns}
              search={this.props.search || true}
              exportCSV={{
                fileName: "custom.csv",
                separator: ";",
                ignoreHeader: false,
                noAutoBOM: false,
              }}
            >
              {(props) => (
                <div>
                  {this.props.search && (
                    <div>
                      <MyExportCSV {...props.csvProps} />
                      <SearchBar
                        {...props.searchProps}
                        style={{
                          width: 500,
                          margin: 10,
                          float: "right",
                        }}
                        delay={200}
                        // onSearch={this.handleSearch}
                        placeholder="Rechercher..."
                      />

                      {this.props.showControlButtons && (
                        <Button
                          type="default"
                          title="Créer un nouveau devis"
                          onClick={this.onShowAddEvent}
                          style={{
                            margin: 10,
                            position: "absolute",
                            top: 10,
                            height: 37,
                            marginLeft: -5,
                          }}
                        >
                          <FontAwesome name="plus" size={18} />
                        </Button>
                      )}
                    </div>
                  )}

                  <PaginationProvider
                    pagination={paginationFactory(options)}
                    style={{ zIndex: 10 }}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <div>
                        <BootstrapTable
                          {...props.baseProps}
                          style={{ border: 1 }}
                          defaultSorted={this.props.defaultSorted || null}
                          bootstrap4
                          hover
                          // remote={{
                          //   search: true,
                          // }}
                          ref={this.table}
                          onTableChange={this.onTableChange}
                          noDataIndication={() => <div>Aucun résultat...</div>}
                          expandRow={this.props.expandRow}
                          rowEvents={this.props.rowEvents}
                          expanded={this.props.LinetoExpand}
                          {...paginationTableProps}
                          wrapperClasses="table-responsive"
                        />
                        {this.props.pagination !== false && (
                          <>
                            {list.length > 0 && (
                              <SizePerPageDropdownStandalone
                                variation="dropup"
                                {...paginationProps}
                                onSizePerPageChange={this.onSizePerPageChange}
                              />
                            )}
                            <PaginationListStandalone
                              {...paginationProps}
                              onPageChange={this.onPageChange}
                            />
                          </>
                        )}
                      </div>
                    )}
                  </PaginationProvider>
                </div>
              )}
            </ToolkitProvider>
          )}
        </ContainerDimensions>
      </React.Fragment>
    );
  }
}

export default ScrollTable;
